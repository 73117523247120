@mixin display-flex {
    display: -webkit-flex;
    display: flex;
}

@mixin order($value) {
    -webkit-order: $value;
    order: $value;
}

@mixin flex-direction($value) {
    -webkit-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-flow($value) {
    -webkit-flex-flow: $value;
    flex-flow: $value;
}

@mixin flex-wrap($value) {
    -webkit-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin flex-grow($value) {
    -webkit-flex-grow: $value;
    flex-grow: $value;
}

@mixin flex-shrink($value) {
    -webkit-flex-shrink: $value;
    flex-shrink: $value;
}

@mixin flex-basis($value) {
    -webkit-flex-basis: $value;
    flex-basis: $value;
}

@mixin flex($value) {
    -webkit-flex: $value;
    flex: $value;
}

@mixin justify-content($value) {
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin align-content($value) {
    -webkit-align-content: $value;
    align-content: $value;
}

@mixin align-items($value) {
    -webkit-align-items: $value;
    align-items: $value;
}

@mixin align-self($value) {
    -webkit-align-self: $value;
    align-self: $value;
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow:    $top $left $blur $color #{$inset};
  box-shadow:         $top $left $blur $color #{$inset};
}

@mixin font-size($sizeValue: 1.6, $content:null) {
    font-size: ($sizeValue * 10) + px $content;
    font-size: $sizeValue + rem $content;
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}
 
@mixin padding($top: null, $right: null, $bottom: null, $left: null, $all: null) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
	padding: $all;
}
@mixin margin($top: null, $right: null, $bottom: null, $left: null, $all: null) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
	margin: $all;
}

@mixin opacity($op) {
    -khtml-opacity: $op;
    -moz-opacity: $op;
    opacity: $op;
}
 
@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}
 
@mixin breakpoint($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == min {
            $width: $width + 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}