//colors
$white: #fff;
$black: #000;
$gray: #dedede;
$gray-light: #f9f9f9;
$gray-lighter: #ededed;
$gray-dark: #bfbfbf;
$gray-darker: #b4b4b4; 

//breakpoints
$breakpoints: ( 'mobile-xs':480px, 'mobile': 767px, 'tablet': 992px, 'desktop': 1024px);