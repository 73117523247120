.cookie {
	&__bar {
		@include box-sizing('border-box');
		font-size: 12px;
		font-family: inherit;
		@include fixed(left 0);
		width: 100%;
		line-height: 16px;
		z-index: 2147483647;
		*,
		*::before,
		*::after {
			@include box-sizing(inherit);
		}
		&.bottom {
			bottom: 0
		}
		&.top {
			top: 0
		}
		&-text {
			@include flex-basis(56%);
			@include margin($right: auto);
			@include breakpoint('desktop') {
				@include margin($bottom: 10px);
				@include flex-basis(100%);
			}
		}
		&-link {
			@include margin($left: 5px)
		}
		&.text {
			&--white {
				color: $white
			}
			&--black {
				color: $black
			}
		}
		a {
			color: inherit;
			text-decoration: none;
			font-weight: 700
		}
		.cookie__btn {
			cursor: pointer;
			text-transform: uppercase;
			border-width: 2px;
			border-style: solid;
			@include padding($left: 35px, $right: 35px, $top:10px, $bottom:10px);
			@include margin($left: 15px);
			width: auto;
			@include breakpoint('mobile-xs') {
				@include flex-basis(100%);
				text-align: center
			}
			&.btn {
				&--settings {
					@include breakpoint('desktop') {
						@include margin($left: auto)
					}
					@include breakpoint('mobile-xs') {
						@include order(1);
					}
				}
				&--allow {
					@include breakpoint('mobile-xs') {
						@include margin($left: auto, $bottom: 10px)
					}
				}
			}
		}
	}
	&__container {
		@include display-flex;
		@include flex-wrap(wrap);
		@include align-items(center);
		@include margin($all: 0);
		@include padding($left: 25px, $right: 25px, $top:15px, $bottom:15px);
	}
	&__modal {
		display: none;
		*,
		*::before,
		*::after {
			@include box-sizing(inherit);
		}
		&.active {
			@include box-sizing('border-box');
			@include display-flex;
			@include justify-content(center);
			@include align-items(center);
			@include fixed(top 0 left 0);
			height: 100vh;
			width: 100vw;
			background-color: rgba(0, 0, 0, .75);
			z-index: 2147483647;
			font-family: inherit;
		}
		&-container {
			@include display-flex;
			@include flex-direction(column);
			@include align-items(center);
			width: 100%;
			height: 100%;
			max-width: 600px;
			max-height: 450px;
			background-color: #fff;
			overflow: hidden
		}
		&-header {
			@include display-flex;
			@include align-items(center);
			height: 80px;
			@include relative;
			width: 100%
		}
		&-close {
			@include absolute(right 20px top 20px);
			font-family: 'Trebuchet MS', sans-serif;
			cursor: pointer;
		}
		&-logo {
			width: 150px;
			height: 80px;
			@include relative;
			@include display-flex;
			@include align-items(center);
			&::after {
				content: '';
				background-color: $white;
				width: 1px;
				height: 55px;
				@include absolute(top 50% right 0);
				transform: translateY(-50%);
				@include breakpoint('mobile') {
					display: none;
				}
			}
			img {
				width: 100%;
				@include padding($all: 0 15px)
			}
		}
		&-title {
			text-transform: uppercase;
			color: $white;
			@include padding($left: 40px);
			font-size: 20px;
			@include breakpoint('mobile') {
				display: none;
			}
		}
		&-body {
			@include flex-grow(1);
			width: 100%; // fix Chrome Safari
			@include display-flex;
			background: $white
		}
		&-tabs {
			@include display-flex;
			@include flex-grow(1);
			@include flex-wrap(wrap);
			font-family: Arial, Helvetica, sans-serif;
			>ul {
				@include margin($all: 0);
				@include padding($all: 0);
				list-style: none;
				max-width: 150px;
				background: $gray-light;
				font-size: 12px;
				@include breakpoint('mobile') {
					@include display-flex;
					@include flex-wrap(wrap);
					@include flex-basis(100%);
					max-width: none;
				}
				li {
					@include padding($left: 10px, $right:30px, $top:25px, $bottom:25px);
					border-bottom: 1px solid $gray-darker;
					color: $gray-dark;
					background-color: $gray-lighter;
					@include breakpoint('mobile') {
						@include flex-basis(100%);
						@include padding($all: 10px)
					}
					&:not(.cookie__current) {
						cursor: pointer;
					}
					&.cookie__current {
						color: $black;
						background-color: $gray;
						@include relative;
						&::after {
							width: 0;
							height: 0;
							border-bottom: 7px solid transparent;
							border-top: 7px solid transparent;
							border-left: 7px solid $gray;
							font-size: 0;
							line-height: 0;
							content: '';
							@include absolute(top 50% right -7px);
							transform: translateY(-50%);
							@include breakpoint('mobile') {
								display: none
							}
						}
					}
					&::before,
					&::after {
						background: none;
						content: '';
					}
				}
			}
			&-content {
				display: none;
				overflow: hidden;
				&.cookie__current {
					@include display-flex;
					@include flex-direction(column);
					@include padding($left: 40px, $right: 40px, $top:20px, $bottom:20px);
					max-height: 330px;
					@include flex(1);
					color: $black;
					@include breakpoint('mobile') {
						max-width: none;
						@include padding($all: 20px);
						max-height: 220px;
					}
				}
				.cookie__modal-title {
					display: none;
					@include breakpoint('mobile') {
						display: block;
						color: $black;
						@include padding($left: 0);
						@include margin($bottom: 10px);
						font-size: 16px;
					}
				}
				&-title {
					font-size: 15px;
					font-weight: 700;
					text-transform: uppercase;
					@include margin($bottom: 25px);
					@include display-flex;
					@include align-items(center);
					>span {
						@include flex-basis(70%)
					}
					label {
						@include margin($left: auto);
						text-transform: none;
						color: $gray;
						font-weight: 700;
						font-size: 14px;
						line-height: 1;
						&[for] {
							@include relative;
							cursor: pointer;
							&::before {
								content: '';
								width: 10px;
								height: 10px;
								box-shadow: inset 0 0 0 1px $white;
								border: 1px solid $gray;
								@include absolute(left -25px top 2px);
								border-radius: inherit;
							}
							span {
								background-color: $white;
								display: block
							}
						}
					}
					input {
						@include absolute(left -99999px);
						&:checked {
							+label {
								&::before {
									background-color: inherit;
									border-width: 1px;
									border-style: solid;
									border-color: inherit
								}
							}
						}
					}
				}
				&-text {
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					max-height: 90%;
					overflow-y: auto;
					overflow-x: hidden;
					p, ul {
						@include margin($top:0);
					} 
				}
				&-list {
					@include margin($top: 20px);
					font-weight: 700;
					span {
						font-size: 14px;
						text-transform: uppercase;
						display: block;
						@include margin($bottom: 10px);
					}
					li {
						list-style-type: disc;
						@include margin($left: 10px);
					}
				}
			}
		}
		&-footer {
			width: 100%;
			height: 40px;
			@include display-flex;
			@include align-items(center);
			@include justify-content(flex-end);
			@include padding($right: 20px)
		}
		.cookie__btn {
			text-transform: uppercase;
			border-width: 2px;
			border-style: solid;
			background: $white;
			font-size: 10px;
			font-weight: 700;
			@include padding($left: 15px, $right: 15px, $top:5px, $bottom:5px);
			cursor: pointer;
			line-height: 1.5;
			&-close {
				background-color: $white;
				line-height: 1;
				width: 20px;
				height: 20px;
				@include display-flex;
				@include align-items(center);
				@include justify-content(center);
			}
		}
	}
}